
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import { useEffect } from 'react';
import { Box, Button, Link, Text, UnorderedList, ListItem } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import FadeIn from 'components/transitions/FadeIn';
import ReactPlayer from 'react-player';
import NextLink from 'next/link';
import useUser from 'hooks/useUser';
import Tracking from 'utils/tracking';
import { useRouter } from 'next/router';

const errorPage = () => {
  const { t } = useTranslation('error');
  const { userData } = useUser();
  const router = useRouter();
  useEffect(() => {
    Tracking.pageView(
      '404',
      router.locale,
      `/${router.locale}${router.asPath}`,
      undefined,
      undefined,
      userData?.id,
      'Page not found'
    );
  }, [router.locale]);
  return (
    <FadeIn>
      <Box mb={'-35px'}>
        <Box
          pos="relative"
          display="flex"
          mb={'-35px'}
          flexDirection={{
            base: 'column',
            lg: 'row',
          }}
          className="error-page"
        >
          <ReactPlayer
            url={'/assets/404.mp4'}
            loop={true}
            playing={true}
            controls={false}
            volume={0}
            width="100%"
            height="100%"
          />

          <Box
            w={{
              base: '100%',
              lg: '50%',
            }}
            p={{
              base: '20px 0',
              md: '50px 0',
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex="1"
            className="message"
          >
            <Box>
              <Text
                fontSize={{
                  base: '78px',
                  md: '94px',
                }}
                lineHeight="45px"
                fontWeight="bold"
                color="white"
                pb="70px"
                pl={{
                  base: '15px',
                  md: '0',
                }}
                pr={{
                  base: '15px',
                  md: '0',
                }}
              >
                {'Oops...'}
              </Text>

              <Text
                fontSize={{
                  base: '24px',
                  md: '32px',
                }}
                lineHeight="25px"
                color="white"
                pl={{
                  base: '15px',
                  md: '0',
                }}
                pr={{
                  base: '15px',
                  md: '0',
                }}
              >
                {t('wrong_place')}
              </Text>

              <NextLink href={'/'} passHref prefetch={false}>
                <Link _hover={{ textDecoration: 'none' }}>
                  <Button
                    w={{
                      base: '100%',
                      lg: '350px',
                    }}
                    display={'flex'}
                    variant={'outlineInverted'}
                    marginTop="40px"
                  >
                    {t('back_to_home')}
                  </Button>
                </Link>
              </NextLink>
            </Box>
          </Box>
          <Box
            w={{
              base: '100%',
              lg: '50%',
            }}
            h={{
              base: 'auto',
              md: '450px',
            }}
            p={{
              base: '25px 0',
              md: '0 0',
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex="1"
            className="message"
          >
            <Box>
              <Text
                fontSize="26px"
                lineHeight={{
                  base: '33px',
                  md: '45px',
                }}
                fontWeight="bold"
                color="white"
                pb="25px"
                pl={{
                  base: '15px',
                  md: '0',
                }}
                pr={{
                  base: '15px',
                  md: '0',
                }}
              >
                {t('reasons_here')}
              </Text>

              <UnorderedList
                fontSize={{
                  base: '18px',
                  md: '27px',
                }}
                lineHeight={{
                  base: '30px',
                  md: '35px',
                }}
                color="white"
                pl={{
                  base: '15px',
                  md: '0',
                }}
                pr={{
                  base: '15px',
                  md: '0',
                }}
              >
                <ListItem>{t('page_changed_address')}</ListItem>
                <ListItem>{t('page_not_exists')}</ListItem>
                <ListItem>{t('like_404')}</ListItem>
                <ListItem>{t('page_curiosity')}</ListItem>
              </UnorderedList>
            </Box>
          </Box>
        </Box>
      </Box>
    </FadeIn>
  );
};

export default errorPage;


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  